(function($) {

    /**
     * Runs the functions needed to activate float labels on gravity forms.
     */
    function initGravityFormsFloatLabels() {
        $('.ginput_complex').each(function() {
            $(this).parent().attr( 'data-form-float-label', 'complex' );
        });

        $('.form--float-labels').formFloatLabels({
            fieldWrapper: 'li'
        });
    }

    /**
     * Run the init function when the submit button is pressed.
     *
     * The form HTML gets replaced when using AJAX, so the JS manipulation gets wiped out. We need to re-call
     * the functions we called on page load.
     */
    $( document ).ready( initGravityFormsFloatLabels );

    /**
     * Hook into Elementor's popup to recall the init function for any forms in popups.
     */
    $( document ).on( 'elementor/popup/show', () => {
        $( document ).ready( initGravityFormsFloatLabels );
    } );


    /**
     * Run the init function when the submit button is pressed.
     *
     * The form HTML gets replaced when using AJAX, so the JS manipulation gets wiped out. We need to re-call
     * the functions we called on page load.
     */
    $(document).bind('gform_post_render', function(){
        initGravityFormsFloatLabels();
    });

})(jQuery);